<template>
  <!-- 饼图 -->
  <div class="pie-chart-size" v-loading="loading">
    <div
      class="chart-box"
      ref="chartBox"
      style="width: 100%; height: 100%"
      v-if="showBarChart"
    ></div>
    <!-- <div class="hollow-box">
      <div class="hollow-center">
        <span></span>
      </div>
    </div> -->

    <dataNull v-else></dataNull>
  </div>
</template>
<script>
import dataNull from "../chart/dataNull.vue";
// vue文件中引入echarts工具
let echarts = require("echarts");
// 以下的组件按需引入
require("echarts/lib/chart/pie");
require("echarts/lib/component/tooltip"); // tooltip组件
require("echarts/lib/component/title"); // title组件
require("echarts/lib/component/legend"); // legend组件

export default {
  name: "pieChart",
  props: {
    // 饼图数据源
    echartData: {
      type: Array,
      default: () => [],
    },
    // 饼图类型颜色
    seriesColor: {
      type: Array,
      default: () => [
        "#3519FB",
        "#2DBAE9",
        "#897CFD",
        "#A266FF",
        "#FF6B79",
        "#FFC324",
        "#00D698",
        "#00C9F8",
        "#00D698",
        "#3278FF",
      ],
      // 默认是5个类目颜色，超出时传入颜色
      // ["#3519FB","#2DBAE9","#897CFD","#A266FF","FF6B79","#FFC324","#00D698","#00C9F8","#00D698","#3278FF"]
    },
    // 图例是否显示
    legendShow: {
      type: Boolean,
      default: false,
    },
    center: {
      type: Array,
      default: () => ["50%", "50%"],
    },
  },
  data() {
    return {
      //图表
      barChart: {},
      showBarChart: true,
      loading: true,
      barOption: null,
      timer: 0,
    };
  },
  components: {
    dataNull,
  },
  watch: {
    echartData: {
      handler: function (val, oldVal) {
        /**
         * 为解决 各种不同默认数据 以及接口返回数据情况情况做的处理
         */
        let oldValStr = JSON.stringify(oldVal);
        let valStr = JSON.stringify(val);
        if (valStr && !oldValStr) {
          this.loading = true;
          this.timer = setTimeout(() => {
            this.drawChart();
            this.loading = false;
            clearTimeout(this.timer);
          }, 3000);
          return;
        } else if (valStr === oldValStr) {
          return;
        } else {
          clearTimeout(this.timer);
        }
        this.loading = true;
        this.drawChart();
        this.loading = false;
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    // console.log(this.echartData)
    // this.drawChart();
    window.addEventListener("resize", this.resize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.resize);
  },
  methods: {
    //数据更新
    drawChart: function () {
      this.showBarChart = true;
      if (this.echartData.length === 0) {
        return false;
      } else {
        let num = 0;
        this.echartData.forEach((v) => {
          if ((v.value && Number(v.value) === 0) || !v.value) {
            num += 1;
          }
        });
        if (num === this.echartData.length) {
          this.showBarChart = false;
          return false;
        }
      }
      let echartData = this.echartData.filter((item) => !!item.value);
      echartData.forEach((item, index) => {
        if (echartData.length <= 20 || (echartData.length > 20 && index % 2 === 0)) {
          // 数量超过20时，每隔一个数据展示label,不超过20时都展示label
          item.label = {
            show: true
          }
          item.labelLine = {
            show: true
          }
        }
      })
      //整理饼图配置
      this.barOption = {
        grid: {
          top: 0,
          left: 20,
          bottom: 20,
          right: 20,
          containLabel: true,
        },
        tooltip: {
          trigger: "item",
          formatter: "{b}：{d}%",
        },
        legend: {
          type: "scroll",
          show: this.legendShow,
          x: "center", //可设定图例在左、右、居中 left  center  right
          y: "bottom", //可设定图例在上、下、居中left  center  right
        },
        graphic: {
          elements: [
            {
              type: "ring",
              left: "center",
              top: "middle",
              transition: ["x", "y"],
              shape: {
                r: 12,
                r0: 10,
              },
              style: {
                fill: "rgba(184, 179, 218,0.31)",
              },
            },
          ],
        },
        series: [
          {
            type: "pie",
            radius: [18, 73],
            center: this.center,
            // color: this.seriesColor,
            minAngle: 5,
            avoidLabelOverlap: true,
            itemStyle: {},
            // 随机颜色
            /* itemStyle: {
              color: function () {
                return (
                  "rgb(" +
                  [
                    Math.round(Math.random() * 270),
                    Math.round(Math.random() * 370),
                    Math.round(Math.random() * 400),
                  ].join(",") +
                  ")"
                );
              },
            }, */
            /* labelLine: { show: false },
            labelLayout: {
              hideOverlap: false,
            }, */
            label: {
              show: false,
              formatter: "{b}：{d}%",
              // label ui图样式，有显示问题，和产品确认更换显示形式
              /* backgroundColor: "#ffffff",
              padding: [2, 8],
              borderColor: "#EEEEEE",
              borderWidth: 2,
              borderRadius: [4],
              lineHeight: 12,
              formatter: "{name|{b}}\n{value|{d}%}",
              rich: {
                name: {
                  color: "rgba(0,0,0,0.7)",
                  fontSize: 11,
                  align: "center",
                },
                value: {
                  color: "inherit",
                  align: "center",
                  fontSize: 11,
                },
              }, */
            },
            data: echartData,
          },
        ],
      };
      this.$nextTick(() => {
        // 基于准备好的dom，初始化echarts实例
        this.myChart = echarts.init(this.$refs.chartBox);
        // 绘制图表
        this.myChart.setOption(this.barOption);
      });
      //动态改变图表尺寸（适配）
      setTimeout(() => {
        this.myChart.resize();
      }, 20);
      window.addEventListener("resize", () => {
        this.myChart.resize();
      });
    },
    resize() {
      this.myChart && this.myChart.resize();
    },
  },
};
</script>
<style lang="less">
.pie-chart-size {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;

  .chart-box {
    width: 100%;
    height: 100%;
  }

  .hollow-box {
    width: 36px;
    height: 36px;
    background: #ffffff;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -18px 0 0 -18px;
    border-radius: 50%;
    .hollow-center {
      width: 26px;
      height: 26px;
      opacity: 0.31;
      border: 2px solid #b8b3da;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 50%;
      margin: -13px 0 0 -13px;
      span {
        width: 16px;
        height: 16px;
        background: #f5f3ff;
        border: 2px solid #170887;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -8px 0 0 -8px;
      }
    }
  }
}
</style>
