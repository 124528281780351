<template>
  <!-- 折线统计图 -->
  <div class="line-chart-size" v-loading="loading">
    <div
      class="chart-box"
      ref="chartBox"
      style="width: 100%; height: 100%"
      v-show="echartData.length !== 0"
    ></div>
    <dataNull v-if="echartData.length === 0"></dataNull>
  </div>
</template>
<script>
import dataNull from "../chart/dataNull.vue";
import { numberFormat } from "js/utils.js";
// vue文件中引入echarts工具
let echarts = require("echarts/lib/echarts");
// 以下的组件按需引入
require("echarts/lib/chart/line");
require("echarts/lib/component/tooltip"); // tooltip组件
require("echarts/lib/component/title"); // title组件
require("echarts/lib/component/legend"); // legend组件
require("echarts/lib/component/dataZoom");

export default {
  name: "lineChart",
  props: {
    //折线图数据，格式：{name:'',value:''}
    echartData: {
      type: Array,
      default: () => [],
    },
    lineColor: {
      type: String,
      default: "#3519FB",
    }, //折线颜色
    textColor: {
      type: String,
      default: "#848295",
    }, //坐标轴文字颜色
    colorStopsStart: {
      type: String,
      default: "rgba(62,78,255,0.1)",
    }, // 折线区域渐变色开始
    colorStopsEnd: {
      type: String,
      default: "rgba(62,78,255,0)",
    }, // 折线区域渐变色结束
    categoryTitle: {
      type: String,
      default: "",
    }, // 类别名称
    minLength: {
      type: Number,
      default: 0,
    }, //最小一行数据
    maxLength: {
      type: Number,
      default: 6,
    }, //最大一行数据
    isSmooth: {
      type: Boolean,
      default: false,
    },
    tooltipFormatter: {
      type: String,
      default: "",
    }, // 提示框组件自定义显示内容
  },
  data() {
    return {
      //图表
      lineChart: {},

      lineOption: null,

      showLineLength: this.maxLength,
      loading: true,
      timer: 0,
    };
  },
  computed: {},
  components: {
    dataNull,
  },
  watch: {
    echartData: {
      handler: function (val, oldVal) {
        /**
         * 为解决 各种不同默认数据 以及接口返回数据情况情况做的处理
         */
        let oldValStr = JSON.stringify(oldVal);
        let valStr = JSON.stringify(val);
        if (valStr && !oldValStr) {
          this.loading = true;
          this.timer = setTimeout(() => {
            this.drawChart();
            this.loading = false;
            clearTimeout(this.timer);
          }, 3000);
          return;
        } else if (valStr === oldValStr) {
          return;
        } else {
          clearTimeout(this.timer);
        }
        this.loading = true;
        this.drawChart();
        this.loading = false;
      },
      deep: true,
      immediate: true,
    },
  },

  mounted() {
    // this.drawChart();
    // console.log("val",this.categoryTitle,this.echartData);
  },
  beforeDestroy() {
    window.addEventListener("resize", () => {
      this.myChart.resize();
    });
  },
  methods: {
    //数据更新
    drawChart: function () {
      if (this.echartData.length === 0) {
        return false;
      }
      this.loading = true;
      // 整理数据
      let xAxisText = [];
      let dataList = [];
      let allData = this.echartData;
      this.echartData.forEach((item) => {
        xAxisText.push(item.name);
        dataList.push(item.value);
      });
      //整理折线图配置
      this.lineOption = {
        title: {},
        grid: {
          // containLabel: true,
          left: 55,
          right: 50,
          bottom: 60,
          top: 20,
        },
        tooltip: {
          show: true,
          trigger: "axis",
          axisPointer: {
            type: "line",
          },
          formatter:
            this.tooltipFormatter ||
            "{b}" +
              (this.categoryTitle ? `<br/>${this.categoryTitle}：` : "") +
              "{c}",
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: xAxisText,
          axisLabel: {
            show: true,
            // showMaxLabel: false, //显示最大刻度
            textStyle: {
              color: this.textColor || "#000000", //这里是改变字体颜色
            },
          },
          //隐藏刻度线
          axisTick: {
            show: false,
          },
          //隐藏轴线
          axisLine: {
            show: true,
            lineStyle: {
              color: "#EEEEEE",
            },
          },
        },
        yAxis: {
          type: "value",
          minInterval: 1,
          axisLabel: {
            formatter: function (value) {
              return numberFormat(value, 0, true) || value;
            },
          },
          splitLine: {
            //网格线
            lineStyle: {
              type: "dashed", //设置网格线类型 dotted：虚线   solid:实线
            },
            show: true, //隐藏或显示
          },
        },
        series: [
          {
            data: dataList,
            type: "line",
            smooth: this.isSmooth || false,
            symbol: "circle",
            // 填充区域样式
            areaStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: this.colorStopsStart, // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: this.colorStopsEnd, // 100% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              },
            },
            // 折线小圆点样式
            itemStyle: {
              color: "#FFFFFF",
              borderColor: this.lineColor,
              borderWidth: 1,
              borderType: "solid",
            },
            // 折线样式
            lineStyle: {
              color: this.lineColor,
            },
            labelLine: {
              show: true,
            },
          },
        ],
      };
      //根据数据量判断是否左右滑动
      if (this.echartData.length > this.showLineLength) {
        this.lineOption.dataZoom = [
          {
            height: 30,
            bottom: 5,
            textStyle: {
              fontSize: 10,
              width: 40,
              overflow: "break",
            },
          },
          // {
          //   // type: "slider",
          //   start: this.minLength,
          //   end: this.showLineLength,
          //   height: "15",
          //   bottom: "5",
          //   showDataShadow: false, //是否显示数据阴影
          //   showDetail: false, //是否显示想洗数值信息
          //   //zoomOnMouseWheel: false, //滚轮是否触发缩放
          //   //moveOnMouseMove: true, //鼠标滚轮触发滚动
          //   //moveOnMouseWheel: true,
          //   zoomLock: true,
          // },
        ];
      }
      // 基于准备好的dom，初始化echarts实例
      this.myChart = echarts.init(this.$refs.chartBox);
      // 绘制图表
      this.myChart.setOption(this.lineOption);

      //动态改变图表尺寸（适配）
      setTimeout(() => {
        this.myChart.resize();
        this.loading = false;
      }, 20);
      window.addEventListener("resize", () => {
        this.myChart.resize();
      });
    },
  },
};
</script>
<style lang="less">
.line-chart-size {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  .chart-box {
    width: 100%;
    height: 100%;
  }
}
</style>
