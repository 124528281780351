var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "pie-chart-size",
    },
    [
      _vm.showBarChart
        ? _c("div", {
            ref: "chartBox",
            staticClass: "chart-box",
            staticStyle: { width: "100%", height: "100%" },
          })
        : _c("dataNull"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }